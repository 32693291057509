<template>
  <el-aside>
    <el-input placeholder="输入关键字进行过滤" v-model="filterText"></el-input>

    <el-tree class="filter-tree" :data="treeData" :props="defaultProps" @node-click="handleTreeNodeClick" node-key="expanded" :default-expanded-keys="[1]" :filter-node-method="filterNode" ref="tree"></el-tree>
  </el-aside>
</template>

<script>
import { ThinkEditorDemo } from '@/demo/ThinkEditorDemo';
import { E_EVENT_KEY, E_DOC_TYPE, E_VIEW_MODE } from '@/components/editor/ThinkEditor.Defined';
import { editorsInstanceManager } from '@/components/editorHelper/ThinkEditorInstance.Manager';

export default {
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  components: {},

  props: {
    modeType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      thinkEditor: undefined,
      thinkEditorDemo: undefined,
      selectedNode: null,
      menuSelectedArr: [],
      leftTagsActiveId: '', // 激活的导航tags id
      filterText: '',
      treeData: [
        {
          label: '演示',
          expanded: 1,
          children: [
            {
              label: '00-演示病例',
              children: [
                {
                  label: '全功能演示病历',
                  document: 'AdmissionRecord.xml',
                  testMode: '1'
                },
                {
                  label: '多级签名',
                  document: 'MultilevelSign.xml'
                }
              ]
            },
            {
              label: '01-模板片段',
              children: [
                {
                  label: '婚否片段',
                  document: 'HunFouFragment.xml'
                },
                {
                  label: '病程片段',
                  document: 'BingChengFragment.xml'
                }
              ]
            },
            {
              label: '02-记录单',
              children: [
                {
                  label: '护理记录单',
                  document: 'NursingRecord.xml'
                },
                {
                  label: '护理记录单_标题行_行线',
                  document: '护理记录单_标题行_行线.xml',
                  testMode: 'SetTableDataTest',
                  viewMode: E_VIEW_MODE.Edit,
                  viewCfg: { displayReviseAuxiliary: true, displayReviseDel: true }
                },
                {
                  label: '产科入院护理评估单',
                  document: '产科入院护理评估单.xml'
                }
              ]
            },
            {
              label: '03-检查检验',
              children: [
                {
                  label: '传染病报告卡',
                  document: 'InfectiousDiseaseCard.xml'
                },
                {
                  label: '检查报告单',
                  document: '检查报告单.xml',
                  testMode: 'SetCellDataTestStyle1'
                },
                {
                  label: '超声检查诊断书',
                  document: '超声检查诊断书.xml',
                  testMode: 'SetCellDataTestCommon'
                }
              ]
            },
            {
              label: '04-时间轴',
              children: [
                {
                  label: '三测单',
                  document: 'TimeAxis.xml',
                  testMode: 'SetTimeDataTest'
                },
                {
                  label: '三测单_无疼痛',
                  document: 'TimeAxis_无疼痛.xml',
                  testMode: 'SetTimeDataTest'
                },
                {
                  label: '三测单-隐藏心率-阴影线',
                  document: 'TimeAxisHide.xml',
                  testMode: 'SetTimeDataTest'
                },
                {
                  label: '三测单Model1',
                  document: '三测单Model1.xml',
                  testMode: 'SetTimeDataTest'
                }
              ]
            },
            {
              label: '05-表格演示',
              children: [
                {
                  label: '表格A',
                  document: 'TestTableSplitA.xml'
                },
                {
                  label: '表格A1',
                  document: 'TestTableSplitA1.xml'
                },
                {
                  label: '表格A2',
                  document: 'TestTableSplitA2.xml'
                },
                {
                  label: '表格B',
                  document: 'TestSplitWholeRow.xml'
                },
                {
                  label: '表格C1',
                  document: 'TestTableSplitC1.xml'
                }
              ]
            },
            {
              label: '06-几百页测试',
              children: [
                {
                  label: '仅需数秒',
                  document: 'AdmissionRecord_200.xml'
                }
              ]
            },
            {
              label: '07-高级表单',
              children: [
                {
                  label: '护理记录单_标题行_行线',
                  document: '护理记录单_标题行_行线.xml',
                  testMode: 'SetTableDataTest',
                  viewMode: E_VIEW_MODE.Edit,
                  viewCfg: { displayReviseAuxiliary: true, displayReviseDel: true }
                },
                {
                  label: '元素_下划线_边框_限宽',
                  document: '元素_下划线_边框_限宽.xml'
                },
                {
                  label: '算法联动1',
                  document: '算法联动1.xml'
                },
                {
                  label: '动态选项',
                  document: '动态选项.xml',
                  testMode: 'DynamicOptionsTest'
                }
              ]
            },
            {
              label: '08-权限管理',
              children: [
                {
                  label: '只在元素中编辑',
                  document: 'AdmissionRecord.xml',
                  viewMode: E_VIEW_MODE.Edit,
                  docConfig: { strictEdit: true }
                }
              ]
            },
            {
              label: '09-病程合并',
              children: [
                {
                  label: '合并编辑',
                  document: '张三 病程记录',
                  unionTest: { doUnion: true },
                  viewMode: E_VIEW_MODE.Edit,
                  docConfig: { strictEdit: true }
                },
                {
                  label: '张三 2021-09-09 病程记录',
                  document: '张三 2021-09-09 病程记录.xml',
                  unionTest: { selectSubDoc: true },
                  viewMode: E_VIEW_MODE.Edit,
                  docConfig: { strictEdit: true }
                },
                {
                  label: '张三 2021-09-10 病程记录',
                  document: '张三 2021-09-10 病程记录.xml',
                  unionTest: { selectSubDoc: true },
                  viewMode: E_VIEW_MODE.Edit,
                  docConfig: { strictEdit: true }
                },
                {
                  label: '张三 2021-09-11 病程记录',
                  document: '张三 2021-09-11 病程记录.xml',
                  unionTest: { selectSubDoc: true },
                  viewMode: E_VIEW_MODE.Edit,
                  docConfig: { strictEdit: true }
                },
                {
                  label: '张三 2021-09-12 病程记录',
                  document: '张三 2021-09-12 病程记录.xml',
                  unionTest: { selectSubDoc: true },
                  viewMode: E_VIEW_MODE.Edit,
                  docConfig: { strictEdit: true }
                },
                {
                  label: '张三 2021-09-13 病程记录',
                  document: '张三 2021-09-13 病程记录.xml',
                  unionTest: { selectSubDoc: true },
                  viewMode: E_VIEW_MODE.Edit,
                  docConfig: { strictEdit: true }
                }
              ]
            }
          ]
        },
        {
          label: '功能',
          expanded: 0,
          children: [
            {
              label: '常规',
              expanded: 1,
              children: [
                {
                  label: '批注&答复',
                  caseName: '批注',
                  testMode: 'CommentTest'
                },
                {
                  label: '全文违禁字&元素质控',
                  caseName: '全文违禁字质控',
                  testMode: 'QualityControlTest'
                },
                {
                  label: '痕迹报告',
                  caseName: '痕迹报告',
                  testMode: 'ReviseTest'
                },
                {
                  label: '大纲导航',
                  caseName: '大纲导航',
                  testMode: 'OutlineTest'
                },
                {
                  label: '按钮点击事件',
                  caseName: '按钮点击事件',
                  testMode: 'ButtonTest'
                },
                {
                  label: '查找替换',
                  caseName: '查找替换',
                  testMode: 'SearchReplaceTest'
                },
                {
                  label: '水印',
                  caseName: '水印',
                  testMode: 'WaterMarkTest'
                },
                {
                  label: '视图比例',
                  caseName: '视图比例',
                  testMode: 'DisplayScaleTest'
                },
                {
                  label: '获取框选内容',
                  caseName: '获取框选内容',
                  testMode: 'GetSelectRangeContentTest'
                },
                {
                  label: '对象是否打印',
                  caseName: '对象是否打印'
                }
              ]
            },
            {
              label: '打印',
              expanded: 1,
              children: [
                {
                  label: '获得打印数据(Electron打印)',
                  caseName: '获得打印数据(Electron打印)'
                },
                {
                  label: '多文档合并打印',
                  caseName: '多文档合并打印'
                },
                {
                  label: '多文档合并(直接打印)',
                  caseName: '多文档合并(直接打印)'
                },
                {
                  label: '旋转打印',
                  caseName: '旋转打印'
                },
                {
                  label: '奇页打印',
                  caseName: '奇页打印'
                },
                {
                  label: '偶页打印',
                  caseName: '偶页打印'
                },
                {
                  label: '打印页码范围1,2,4-5',
                  caseName: '页码范围'
                },
                {
                  label: '空元素不打印',
                  caseName: '空元素不打印'
                }
              ]
            },
            {
              label: '权限',
              expanded: 1,
              children: [
                {
                  label: '跨病历粘贴',
                  caseName: '跨病历粘贴',
                  testMode: 'CheckDocIdTest'
                },
                {
                  label: '行[归属]权限+签名',
                  caseName: '行权限+签名',
                  testMode: 'RowOwnerTest'
                },
                {
                  label: '[归属]-权限',
                  caseName: '单元格、段落所属权限',
                  testMode: 'OwnerTest'
                },
                {
                  label: '[声明]-资格-权限',
                  caseName: '行、单元格、段落声明权限',
                  testMode: 'ClaimTest'
                }
              ]
            }
          ]
        },
        {
          label: '模版',
          expanded: 1,
          children: [
            {
              label: '00-住院病案首页',
              children: [
                {
                  label: '住院病案首页-通用',
                  document: '住院病案首页-通用.xml'
                },
                {
                  label: '住院病案首页-广东',
                  document: '住院病案首页-广东.xml'
                },
                {
                  label: '住院病案首页-河南',
                  document: '住院病案首页-河南.xml'
                },
                {
                  label: '住院病案首页-湖南',
                  document: '住院病案首页-湖南.xml'
                },
                {
                  label: '住院病案首页-山东',
                  document: '住院病案首页-山东.xml'
                },
                {
                  label: '住院病案首页-陕西',
                  document: '住院病案首页-陕西.xml'
                },
                {
                  label: '住院病案首页-云南',
                  document: '住院病案首页-云南.xml'
                },
                {
                  label: '住院病案首页-浙江',
                  document: '住院病案首页-浙江.xml'
                }
              ]
            },
            {
              label: '01-住院志',
              children: [
                {
                  label: '24小时内入出院记录',
                  document: '24小时内入出院记录.xml'
                },
                {
                  label: '24小时内入院死亡记录',
                  document: '24小时内入院死亡记录.xml'
                },
                {
                  label: '出院记录-妇科',
                  document: '出院记录-妇科.xml'
                },
                {
                  label: '出院记录-通用',
                  document: '出院记录-通用.xml'
                },
                {
                  label: '出院记录-新生儿科',
                  document: '出院记录-新生儿科.xml'
                },
                {
                  label: '入院记录(痕迹)',
                  document: '入院记录(痕迹).xml'
                },
                {
                  label: '入院记录',
                  document: '入院记录.xml'
                },
                {
                  label: '入院记录--儿科',
                  document: '入院记录--儿科.xml'
                },
                {
                  label: '入院记录--妇科',
                  document: '入院记录--妇科.xml'
                },
                {
                  label: '入院记录--通用',
                  document: '入院记录--通用.xml'
                }
              ]
            },
            {
              label: '02-病程记录',
              children: [
                {
                  label: '出院小结',
                  document: '出院小结.xml'
                },
                {
                  label: '会诊记录',
                  document: '会诊记录.xml'
                },
                {
                  label: '交班记录',
                  document: '交班记录.xml'
                },
                {
                  label: '接班记录',
                  document: '接班记录.xml'
                },
                {
                  label: '麻醉记录单',
                  document: '麻醉记录单.xml'
                },
                {
                  label: '麻醉术前访视记录',
                  document: '麻醉术前访视记录.xml'
                },
                {
                  label: '抢救记录',
                  document: '抢救记录.xml'
                },
                {
                  label: '上级医师查房记录',
                  document: '上级医师查房记录.xml'
                },
                {
                  label: '手术安全核查表',
                  document: '手术安全核查表.xml'
                },
                {
                  label: '手术风险评估表',
                  document: '手术风险评估表.xml'
                },
                {
                  label: '手术护理记录',
                  document: '手术护理记录.xml'
                },
                {
                  label: '手术记录',
                  document: '手术记录.xml'
                },
                {
                  label: '首次病程记录',
                  document: '首次病程记录.xml'
                },
                {
                  label: '输血记录',
                  document: '输血记录.xml'
                },
                {
                  label: '术后首次病程记录',
                  document: '术后首次病程记录.xml'
                },
                {
                  label: '术前讨论记录',
                  document: '术前讨论记录.xml'
                },
                {
                  label: '术前小结',
                  document: '术前小结.xml'
                },
                {
                  label: '死亡记录',
                  document: '死亡记录.xml'
                },
                {
                  label: '死亡讨论记录',
                  document: '死亡讨论记录.xml'
                },
                {
                  label: '疑难病例讨论记录',
                  document: '疑难病例讨论记录.xml'
                },
                {
                  label: '转出记录',
                  document: '转出记录.xml'
                },
                {
                  label: '转入记录',
                  document: '转入记录.xml'
                }
              ]
            },
            {
              label: '03-护理记录单',
              children: [
                {
                  label: 'NICU重症监护单',
                  document: 'NICU重症监护单.xml'
                },
                {
                  label: '护理记录单(神经外科)',
                  document: '护理记录单(神经外科).xml'
                },
                {
                  label: '护理记录单(手术科室)',
                  document: '护理记录单(手术科室).xml'
                },
                {
                  label: '护理记录单(危重)',
                  document: '护理记录单(危重).xml'
                },
                {
                  label: '护理记录单',
                  document: '护理记录单.xml'
                },
                {
                  label: '护理记录单2',
                  document: '护理记录单2.xml'
                },
                {
                  label: '护理记录单3',
                  document: '护理记录单3.xml'
                },
                {
                  label: '护理记录单4',
                  document: '护理记录单4.xml'
                },
                {
                  label: '护理记录单5',
                  document: '护理记录单5.xml'
                },
                {
                  label: '护理记录单6',
                  document: '护理记录单6.xml'
                },
                {
                  label: '护理记录单-录入',
                  document: '护理记录单-录入.xml'
                }
              ]
            },
            {
              label: '04-检查检验',
              children: [
                {
                  label: 'CT检查报告单',
                  document: 'CT检查报告单.xml'
                },
                {
                  label: 'CT检查报告单2',
                  document: 'CT检查报告单2.xml'
                },
                {
                  label: 'CT影像诊断报告单',
                  document: 'CT影像诊断报告单.xml'
                },
                {
                  label: '粪便检验申请单',
                  document: '粪便检验申请单.xml'
                },
                {
                  label: '彩色多普勒超声诊断报告单',
                  document: '彩色多普勒超声诊断报告单.xml'
                },
                {
                  label: '超声检查诊断书',
                  document: '超声检查诊断书.xml'
                },
                {
                  label: '放射检查报告单',
                  document: '放射检查报告单.xml'
                },
                {
                  label: '骨髓细胞学检查申请单',
                  document: '骨髓细胞学检查申请单.xml'
                },
                {
                  label: '急诊科生化、免疫检验申请单',
                  document: '急诊科生化、免疫检验申请单.xml'
                },
                {
                  label: '检查诊疗报告单-消化系疾病',
                  document: '检查诊疗报告单-消化系疾病.xml'
                },
                {
                  label: '检验报告单（血常规）',
                  document: '检验报告单（血常规）.xml'
                },
                {
                  label: '尿液检验申请单',
                  document: '尿液检验申请单.xml'
                },
                {
                  label: '同视机检查报告',
                  document: '同视机检查报告.xml'
                },
                {
                  label: '微生物培养申请单',
                  document: '微生物培养申请单.xml'
                },
                {
                  label: '胃镜检查报告单',
                  document: '胃镜检查报告单.xml'
                },
                {
                  label: '眼部B超检查报告单',
                  document: '眼部B超检查报告单.xml'
                },
                {
                  label: '眼部彩超图文报告',
                  document: '眼部彩超图文报告.xml'
                }
              ]
            }
          ]
        }
      ],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    };
  },
  mounted() {
    // if (!this.is_touch_device) this.focus_text();
    //var canvas = document.getElementById("canvas");
    /*************************
     * 方案一：监听所有事件再分发
     * ***********************/
    //  var thinkEdtior = canvas.thinkEdtior;
    //this.thinkEditor.Init(canvas);
    // thinkEdtior.RegisterEventListener(this.EventListener);//监听所有事件
    /*************************
     * 方案二：监听特定事件
     * ***********************/
    editorsInstanceManager.addEventListener(E_EVENT_KEY.editorFocus, this.OnDocEditorFocusChange);
    let currentEditorsInstance = editorsInstanceManager.GetSelectedEditorsInstance();
    this.thinkEditor = currentEditorsInstance?.GetSelectedEditor();
    this.thinkEditorDemo = new ThinkEditorDemo(this.thinkEditor);
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    OnDocEditorFocusChange(e) {
      this.thinkEditor = e.data.editor;

      this.thinkEditorDemo.bind(this.thinkEditor);
    },
    getDoumentPath(node) {
      let path = ''; // '/' + docNode.document; //xml
      // let node = docNode.parent;

      while (node.label !== undefined) {
        path = node.label + '/' + path;
        node = node.parent;
      }

      return path;
    },
    handleTreeNodeClick(data, node) {
      if (data.caseName !== undefined) {
        this.$emit('doDemoCase', data, node);
        return;
      }
      //@20240517 必要data.document过滤，防止点到外层00-演示病例 仍开了一个tab
      if (data.document === undefined) {
        return;
      }
      let docPath = this.getDoumentPath(node.parent);
      this.$emit('openFile', data.label, docPath + data.document, data, node);
    },
    selected(node) {
      this.selectedNode = node;
      if (this.selectedNode.model.emr) {
        this.$emit('openFile', this.selectedNode);

        // this.thinkEditorDemo.OpenEmr(
        //   this.selectedNode.model.text,
        //   this.selectedNode.model.doc_type,
        //   this.selectedNode.model.emr,
        //   this.selectedNode.model.view_mode
        // );
        // // 判断是否已经存在，如果存在就直接设置激活
        // if (!this.menuSelectedArr.some(e => e.model.id === this.selectedNode.model.id)) {

        //   const { model: modelRes } = this.selectedNode
        //   // 添加数据，修改当前激活的tags
        //   this.menuSelectedArr.push({ model: modelRes })
        // }
        // this.leftTagsActiveId = this.selectedNode.model.id;

        // this.$emit('menuSelected', { menuSelectedArr: this.menuSelectedArr, leftTagsActiveId: this.leftTagsActiveId })
      }
    }
  }
};
</script>

<style></style>
