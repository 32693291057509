<template>
  <div ref="editorContainer" style="width: 100%; height: 100%" />
</template>

<script>
import { ThinkEditor, ThinkEditorEvent, ThinkEditorEventData } from '@/components/editor/ThinkEditor';
import { E_EVENT_HANDLE, E_EVENT_KEY, E_INSTANCE_EVENT_KEY } from '@/components/editor/ThinkEditor.Defined';

export default {
  props: ['thinkEditor'],
  // data() {
  //   return {
  //     thinkEditor1: undefined
  //   };
  // },
  created() {
    // this.thinkEditor = this.thinkEditor;
    console.warn('ThinkEditorView Created:', this.thinkEditor.editorId);
  },

  mounted() {
    console.warn('ThinkEditorView mounted:', this.thinkEditor.editorId);

    if (this.thinkEditor !== undefined && this.thinkEditor.contatiner === undefined) {
      this.thinkEditor.Load(this.$refs.editorContainer);
    }

    //this.createCreatedThinkEditorViewEvent();
  },
  beforeDestroy() {
    console.warn(new Date(), ' ThinkEditorView Destroy:', this.thinkEditor.editorId);
    if (this.thinkEditor !== undefined && this.thinkEditor.contatiner === this.$refs.editorContainer) {
      this.thinkEditor.UnLoad();
    }

    //this.createDestroyThinkEditorViewEvent();
  },
  methods: {
    /*
    async createCreatedThinkEditorViewEvent() {
      let e = new ThinkEditorEvent(
        E_INSTANCE_EVENT_KEY.createdThinkEditorView,
        new ThinkEditorEventData(
          E_EVENT_HANDLE.event,
          E_INSTANCE_EVENT_KEY.createdThinkEditorView,
          {
            ThinkEditorView: this
          },
          docEditor.editorId,
          docEditor.editorInstance
        )
      );
      await this.dispatchEvent(e);
    },
    async createDestroyThinkEditorViewEvent() {
      let e = new ThinkEditorEvent(
        E_INSTANCE_EVENT_KEY.destroyThinkEditorView,
        new ThinkEditorEventData(
          E_EVENT_HANDLE.event,
          E_INSTANCE_EVENT_KEY.destroyThinkEditorView,
          {
            ThinkEditorView: this
          },
          docEditor.editorId,
          docEditor.editorInstance
        )
      );
      await this.dispatchEvent(e);

    }, */
    /**
     * @function dispatchEvent
     * @description ThinkEditorView.vue �� EditorsTabsView.vue
     * @param {ThinkEditorEvent} e
     * @return {}
     * @example
     */
    async dispatchEvent(e) {
      await this.$emit('dispatchEditorViewEvent', e);
    }
  }
};
</script>

<style scoped></style>
