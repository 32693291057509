<template>
  <div class="editor-demo">
    <div class="icon-field">
      <el-card><vue-file-toolbar-menu :content="my_menu_one" /></el-card>
      <el-card><vue-file-toolbar-menu :content="my_menu_two" /></el-card>
      <el-card><vue-file-toolbar-menu :content="my_menu_three" /></el-card>
    </div>
  </div>
</template>

<script>
import VueFileToolbarMenu from 'vue-file-toolbar-menu';

import fontBackColorCom from './fontBackColorCom';
import fontColorCom from './fontColorCom';
import PartMenu from './partMenu';
import PartMenuOrder from './partMenuOrder';
import PartInput from './partInput';
import { fontsManager, E_PASTE_TYPE, E_FERRULE_MODE, E_BRUSH_STATE, E_SCRIPT_MODE, E_PAGE_NUM_STYLE, E_PAGE_NUM_FORMAT, E_CHECK_FIGURE_STYLE, E_ALIGN_HORIZONTAL_MODE, E_SPECIFIC_INDENT_FORMAT, E_VIEW_MODE, E_EVENT_KEY } from '@/components/editor/ThinkEditor.Defined';

import { Pound2CM, RGBA2Color, Color2RGBAStr, RGBAStr2Color } from '@/components/editor/ThinkEditor.Utils';
import { editorsInstanceManager } from '@/components/editorHelper/ThinkEditorInstance.Manager';
export default {
  components: { VueFileToolbarMenu, fontBackColorCom, fontColorCom, PartMenu, PartInput },
  data() {
    return {
      initial_html: 'Powered by ThinkEditor!',
      font: '宋体',
      //font_color: 'rgb(0, 0, 0)',
      fontSizeItem: '小四',
      font_graph: '常规',
      theme: 'default',
      edit_mode: true,
      check1: false,
      check2: false,
      check3: true,
      bold_state_: false,
      script: 0,
      ferrule: 0,
      italic_state_: false,
      strikethrough_state_: false,
      underline_state_: false,
      //thinkEditorDemo: new ThinkEditorDemo(),
      thinkEditor: undefined,
      fontNameList: ['宋体', '黑体']
    };
  },

  computed: {
    my_menu_one() {
      return [
        {
          icon: 'format_clear',
          title: '清除格式',
          //hotkey: this.isMacLike ? 'shift+command+l' : 'ctrl+shift+l',
          click: () => this.thinkEditor.ClearFormat()
        },
        {
          icon: 'format_paint',
          title: '格式刷',
          //hotkey: this.isMacLike ? 'shift+command+e' : 'ctrl+shift+e',
          click: () => this.thinkEditor.SetFormatBrush(E_BRUSH_STATE.BrushOnce)
        },
        {
          icon: 'undo',
          title: '恢复',
          //hotkey: this.isMacLike ? 'shift+command+r' : 'ctrl+shift+r',
          click: () => this.thinkEditor.Undo(1)
        },
        {
          icon: 'redo',
          title: '撤销',
          //hotkey: this.isMacLike ? 'shift+command+j' : 'ctrl+shift+j',
          click: () => this.thinkEditor.Redo(1)
        },

        {
          icon: 'file_copy',
          title: '复制',
          //hotkey: this.isMacLike ? 'ctrl+c' : 'ctrl+c',
          click: () => this.thinkEditor.Copy()
        },
        {
          icon: 'content_cut',
          title: '剪切',
          //hotkey: this.isMacLike ? 'ctrl+x' : 'ctrl+x',
          click: () => this.thinkEditor.Cut()
        },
        {
          icon: 'content_copy',
          title: '粘贴',
          //hotkey: this.isMacLike ? 'ctrl+v' : 'ctrl+v',
          click: () => this.thinkEditor.Paste(E_PASTE_TYPE.Normal)
        }
      ];
    },
    my_menu_two() {
      return [
        {
          html: '<div class="ellipsis" style="width: 80px; font-size: 95%;">' + this.font + '</div>',
          title: 'Font',
          chevron: true,
          menu: this.font_menu,
          menu_height: 200,
          click: async () => {
            this.fontNameList = await fontsManager.GetfontsNameList();
            //this.fontNameList = ['宋体', '黑体', '楷体', '仿宋'];
            this.fontNameList.unshift('宋体', '黑体', '楷体', '仿宋');
          }
        },
        { is: 'separator' },
        {
          html: '<div class="ellipsis" style="width: 60px; font-size: 95%;">' + this.font_graph + '</div>',
          title: 'font-weight',
          chevron: true,
          menu: this.font_glyph_menu,
          menu_height: 200,
          menu_width: 100
        },
        { is: 'separator' },
        {
          html: '<div class="ellipsis" style="width: 60px; font-size: 95%;">' + this.fontSizeItem + '</div>',
          title: 'font-size',
          chevron: true,
          menu: this.font_size_menu,
          menu_height: 200,
          menu_width: 100
        },
        { is: 'separator' },
        {
          icon: 'format_bold',
          title: '加粗',
          active: this.bold_state_,
          //hotkey: this.isMacLike ? "command+b" : "ctrl+b",
          click: () => {
            this.bold_state_ = !this.bold_state_;
            this.thinkEditor.SetFontBold(this.bold_state_);
          }
        },
        {
          icon: 'format_italic',
          title: '斜体',
          active: this.italic_state_,
          //hotkey: this.isMacLike ? "command+i" : "ctrl+i",
          click: () => {
            this.italic_state_ = !this.italic_state_;
            this.thinkEditor.SetFontItalic(this.italic_state_);
          }
        },
        {
          icon: 'format_underline',
          title: '下划线',
          active: this.underline_state_,
          //hotkey: this.isMacLike ? "command+u" : "ctrl+u",
          click: () => {
            this.underline_state_ = !this.underline_state_;
            this.thinkEditor.SetFontUnderline(this.underline_state_);
          }
        },
        {
          icon: 'subtitles_off',
          title: '删除线',
          active: this.strikethrough_state_,
          click: () => {
            this.strikethrough_state_ = !this.strikethrough_state_;
            this.thinkEditor.SetFontStrikethrough(this.strikethrough_state_);
          }
        },
        {
          is: fontColorCom,
          title: '字体'
        },
        {
          is: fontBackColorCom,
          title: '设置背景颜色'
        },
        { is: 'separator' },
        {
          icon: 'superscript',
          active: this.script == 1,
          title: '上标',
          click: () => {
            this.thinkEditor.SetFontScript(E_SCRIPT_MODE.SuperScript);
          }
        },
        {
          icon: 'subscript',
          title: '下标',
          active: this.script == 2,
          click: () => {
            this.thinkEditor.SetFontScript(E_SCRIPT_MODE.SubScript);
          }
        },
        {
          icon: 'donut_large',
          title: '文字套圈',
          menu_width: 100,
          menu: [
            {
              text: '无套圈',
              active: this.ferrule == 0,
              click: () => {
                this.thinkEditor.SetFontFerrule(E_FERRULE_MODE.Normal);
              }
            },
            {
              text: '圆形套圈',
              active: this.ferrule == 1,
              click: () => {
                this.thinkEditor.SetFontFerrule(E_FERRULE_MODE.Circle);
              }
            },
            {
              text: '方形套圈',
              active: this.ferrule == 2,
              click: () => {
                this.thinkEditor.SetFontFerrule(E_FERRULE_MODE.Rectangle);
              }
            }
          ]
        },
        { is: 'separator' }
      ];
    },
    my_menu_three() {
      return [
        {
          icon: 'format_list_bulleted',
          title: '符号列表',
          chevron: true,
          menu: [
            {
              html: "<span  style='font-size:14px;'>符号列表</span>"
            },
            {
              is: PartMenu
            },
            {
              html: "<span style='font-size:14px;'>样式定义</span>"
            },
            {
              is: PartInput
            }
          ]
        },
        {
          icon: 'format_list_numbered',
          title: '有序列表',
          chevron: true,
          menu: [
            {
              html: "<span  style='font-size:14px;'>有序列表</span>"
            },
            {
              is: PartMenuOrder
            },
            {
              html: "<span style='font-size:14px;'>样式定义</span>"
            },
            {
              is: PartInput
            }
          ]
        },
        { is: 'separator' },
        {
          icon: 'format_align_left',
          title: '左对齐',
          click: () => this.thinkEditor.SetParagraphAlignContent(E_ALIGN_HORIZONTAL_MODE.Left)
        },
        {
          icon: 'format_align_center',
          title: '居中对齐',
          click: () => this.thinkEditor.SetParagraphAlignContent(E_ALIGN_HORIZONTAL_MODE.Center)
        },
        {
          icon: 'format_align_right',
          title: '右对齐',
          click: () => this.thinkEditor.SetParagraphAlignContent(E_ALIGN_HORIZONTAL_MODE.Right)
        },
        {
          icon: 'format_align_justify',
          title: '两端对齐',
          click: () => this.thinkEditor.SetParagraphAlignContent(E_ALIGN_HORIZONTAL_MODE.Justify)
        },
        {
          icon: 'horizontal_distribute',
          title: '分散对齐',
          click: () => this.thinkEditor.SetParagraphAlignContent(E_ALIGN_HORIZONTAL_MODE.Distribute)
        },
        //{ is: "separator" },
        // { is: DemoCustomButton, text: "Your component", click: () => alert("Your custom action!") },
        { is: 'separator' },
        {
          icon: 'format_clear',
          text: 'Clear',
          title: 'Clear format',
          click: () => this.thinkEditor.ClearFormat()
        },

        { is: 'separator' },
        {
          icon: 'lock',
          title: '内容锁定',
          click: () => this.thinkEditor.SetSelectContentLock(true)
        },
        {
          icon: 'lock_open',
          title: '内容解锁',
          click: () => this.thinkEditor.SetSelectContentLock(false)
        }
      ];
    },
    isMacLike: () => /(Mac|iPhone|iPod|iPad)/i.test(navigator.platform),
    font_menu() {
      return this.fontNameList.map(font => {
        return {
          html: '<span class="ellipsis" style="font-family:' + font + '">' + font + '</span>',
          icon: this.theme != 'default' && this.font == font ? 'check' : false,
          active: this.font == font,
          height: 20,
          click: async () => {
            this.thinkEditor.SetFontType(font);
            this.font = font;
          }
        };
      });
    },
    /* font_list: () => ['宋体', '楷体', '黑体', '仿宋', 'Garamond', 'Arial', 'Avenir', 'Courier New', 'Garamond', 'Georgia', 'Impact', 'Helvetica', 'Palatino', 'Roboto', 'Times New Roman', 'Verdana'],*/
    font_list() {
      return this.fontNameList;
    },

    font_glyph_menu() {
      return this.font_graph_list.map(font_graph => {
        return {
          html: '<span class="ellipsis" style="font-family:' + font_graph + '">' + font_graph + '</span>',
          icon: this.theme != 'default' && this.font_graph == font_graph ? 'check' : false,
          active: this.font_graph == font_graph,
          height: 20,
          click: () => {
            var fontGraph = {};
            if (font_graph == '加粗 倾斜') {
              fontGraph.bold = true;
              fontGraph.italic = true;
            } else if (font_graph == '加粗') {
              fontGraph.bold = true;
              fontGraph.italic = false;
            } else if (font_graph == '倾斜') {
              fontGraph.bold = false;
              fontGraph.italic = true;
            } else {
              //'常规'
              fontGraph.bold = false;
              fontGraph.italic = false;
            }
            this.thinkEditor.SetFontConfig(fontGraph);
            this.font_graph = font_graph;
          }
        };
      });
    },
    font_graph_list: () => ['常规', '加粗', '倾斜', '加粗 倾斜'],
    font_size_menu() {
      return this.font_size_list.map(sizeItem => {
        return {
          html: '<span class="ellipsis" style="font-family:' + sizeItem.item + '">' + sizeItem.item + '</span>',
          icon: this.theme != 'default' && this.fontSizeItem == sizeItem.item ? 'check' : false,
          active: this.fontSizeItem == sizeItem.item,
          height: 20,
          click: () => {
            let fontSizeCM = Pound2CM(sizeItem.pound);
            this.thinkEditor.SetFontSize(fontSizeCM);
            this.fontSizeItem = sizeItem.item;
          }
        };
      });
    },
    font_size_list: () => [
      {
        item: '初号',
        pound: 42
      },
      {
        item: '小初',
        pound: 36
      },
      {
        item: '一号',
        pound: 26
      },
      {
        item: '小一',
        pound: 24
      },
      {
        item: '二号',
        pound: 22
      },
      {
        item: '小二',
        pound: 18
      },
      {
        item: '三号',
        pound: 16
      },
      {
        item: '小三',
        pound: 15
      },
      {
        item: '四号',
        pound: 14
      },
      {
        item: '小四',
        pound: 12
      },
      {
        item: '五号',
        pound: 10.5
      },
      {
        item: '小五',
        pound: 9
      },
      {
        item: '六号',
        pound: 7.5
      },
      {
        item: '小六',
        pound: 6.5
      },
      {
        item: '七号',
        pound: 5.5
      },
      {
        item: '八号',
        pound: 5
      },
      {
        item: '5',
        pound: 5
      },
      {
        item: '5.5',
        pound: 5.5
      },
      {
        item: '6.5',
        pound: 6.5
      },
      {
        item: '7.5',
        pound: 7.5
      },
      {
        item: '8',
        pound: 8
      },
      {
        item: '10',
        pound: 10
      },
      {
        item: '10.5',
        pound: 10.5
      },
      {
        item: '11',
        pound: 11
      },
      {
        item: '12',
        pound: 12
      },
      {
        item: '14',
        pound: 14
      },
      {
        item: '16',
        pound: 16
      },
      {
        item: '18',
        pound: 18
      },
      {
        item: '20',
        pound: 20
      },
      {
        item: '22',
        pound: 22
      },
      {
        item: '26',
        pound: 26
      },
      {
        item: '28',
        pound: 28
      },
      {
        item: '36',
        pound: 36
      },
      {
        item: '48',
        pound: 48
      },
      {
        item: '56',
        pound: 56
      },
      {
        item: '72',
        pound: 72
      }
    ],
    is_touch_device: () => 'ontouchstart' in window || window.navigator.msMaxTouchPoints > 0
  },
  methods: {
    focus_text() {
      // this.$refs.text.focus();
      document.execCommand('selectAll', false, null);
      document.getSelection().collapseToEnd();
    },
    OnDocEditorFocusChange(e) {
      this.thinkEditor = e.data.editor;
    },
    OnWindowBodyLoaded(e) {
      this.thinkEditor = e.data.editor;
    },
    OnFontFormatChange(e) {
      console.log(e);

      //this.thinkEditor = ThinkEditorManager.GetEditor(editorId);
      let data = e.data.data;
      //console.log(data);
      this.bold_state_ = parseInt(data.bold);
      this.italic_state_ = parseInt(data.italic);
      this.underline_state_ = parseInt(data.underline);
      this.strikethrough_state_ = parseInt(data.strikethrough);
      this.script = parseInt(data.script);
      this.ferrule = parseInt(data.ferrule);

      //this.font_color = Color2RGBAStr(data.color);
      //console.log(this.font_color);
      this.font = data.fontName;
      this.fontSizeItem = data.fontSizeItem;
    },
    EventTest(e) {
      console.log(e);
    }
  },
  async created() {},
  async mounted() {
    //this.font_list = await GetFontNameList();
    //var dd = await this.fick_local_font();
    // if (!this.is_touch_device) this.focus_text();
    //var canvas = document.getElementById("canvas");
    /*************************
     * 方案一：监听所有事件再分发
     * ***********************/
    //  var thinkEdtior = canvas.thinkEdtior;
    //this.thinkEditor.Init(canvas);
    // thinkEdtior.RegisterEventListener(this.EventListener);//监听所有事件
    /*************************
     * 方案二：监听特定事件
     * ***********************/
    editorsInstanceManager.addEventListener(E_EVENT_KEY.editorFocus, this.OnDocEditorFocusChange);
    editorsInstanceManager.addEventListener(E_EVENT_KEY.fontFormat, this.OnFontFormatChange);
    let currentEditorsInstance = editorsInstanceManager.GetSelectedEditorsInstance();

    this.thinkEditor = currentEditorsInstance?.GetSelectedEditor();
  }
};
</script>

<style lang="scss">
.editor-demo {
  position: relative;

  .icon-field {
    display: flex;
    width: 80%;
  }
  .el-card {
    overflow: visible;
    margin-right: 3px;
  }
  .el-card__body,
  .el-main {
    padding: 2px;
  }
}
</style>
